import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h2>Error: Page not found</h2>
    <p>Unfortunately, the page you were looking for does not exist.</p>
    <p>Please <Link to="/">return to the homepage</Link>.</p>
  </Layout>
)

export default NotFoundPage
